.pointing-hand{
    cursor: pointer;
}
.css-uhb5lp{
    max-width:800px !important;
}

.crop-modal-wrapper {
    padding: 22px 33px;
}

.text-bold-heading {
    font-family: Poppins;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.025em;
    color: #3D3D3D;
    margin-bottom: 27px;
}

.see-example {
    font-family: Poppins;
    font-style: italic;
    font-weight: 600;
    font-size: 11px;
    line-height: 139.4%;
    letter-spacing: 0.025em;
    text-decoration-line: underline;
    color: #123FBC;
    cursor: pointer;
}

.text-normal-black {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 139.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #3D3D3D;
}

.text-normal {
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.045em;
    margin: 0;
}

.grey {
    color: #7B7B7B;
}

.error-text {
    color: #D41515;
}

.tooltip-img {
    position: absolute;
    right: 1%;
    top: 13%;
}

.label-crop {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 139.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #3D3D3D;
}

.confirm-upload {
    background: #123FBC;
    border: 1px solid #123FBC;
    box-sizing: border-box;
    box-shadow: -1px -1px 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    color: white;
    padding: 8px 17px;
    font-family: Poppins;
    font-weight: bold;
    font-size: 12px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer;
}
.close-wrapper{
    position: absolute;
    top: 25px;
    right: 29px;
}

.text-grey-crop {
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.045em;
    color: #7B7B7B;
}

.choose-file {
    width: 156px;
    height: 30px;
    color: white;
    background: #123FBC;
    border: 1px solid #123FBC;
    box-sizing: border-box;
    box-shadow: -1px -1px 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Poppins;
    font-weight: bold;
    font-size: 12px;
    line-height: 117.4%;
}
.not-valid-image{
    font-family: Poppins;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.045em;
    color: #D51515;
    margin-top: 10px;
    margin-bottom: 0;
}

.tooltip-sample-img {
    width: 299px;
    height: 358px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-height: calc(100% - 64px);
    max-width: 787px !important;
    overflow: hidden;
}

.css-ypiqx9-MuiDialogContent-root {
    overflow: hidden;
}
